<script>


import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import { getCashApi } from '@/api/cash'




/**
 * User list component
 */
export default {
  page: {
    title: "Account Payable ",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      list: [],
      title: "Account Payable",
      items: [
        {
          text: "EFT List",
          href: "/eft-list",
        },
        {
          text: "EFT Detail",
          href: "/",
        },
      ],
      fields: [

        {
          key: "ID",
          label : '',
          sortable: true,
        },
        {key : 'Payee'},
        {
          key: "EFTNumber",
          label : 'EFT',
          sortable: true,
        },

        {
          key: "Amount",
          sortable: false,
        },
        {
          key: "Date",
          sortable: true,
        },

      ],
     
      selected_transactions: [],
      eft_number : '',
      eft : {
        cash_transactions : []
      }
    };
  },
  components: {
    Layout,
    PageHeader,
    
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.totalRows
    },

  },

  created() {
    this.eft_number = this.$route.query.eft_number 
    getCashApi().eft_detail({eft_number : this.eft_number}).then(res =>{
      if (res.errCode == 0) {
        this.selected_transactions  = res.cash_transactions
        this.eft = res.data
      }
    })
  },
  mounted() {

  },
  methods: {


    onSelectedTransaction(evt, t)  {
      if (evt.target.checked == true) {
        let d = this.selected_transactions.find((e)=> e.id == t.id)
        if (!d) {
          this.selected_transactions.push(t)
        }
      } else {
        this.selected_transactions = this.selected_transactions.filter((e)=> e.id != t.id)
      }
    },

    complete_eft() {
      let data = {
        transaction_code : this.eft.transaction_code
      }
      getCashApi().complete_eft_payment(data).then(res => {
        if (res.errCode == 0) {
          this.$router.push({name : 'ap-eft-list'})
          this.$alertify.message("Success");
        } else {
          this.$alertify.error(" Operation Failed " + res.errCode);
        }
      })
    }



  },

};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
 

            <div class="row">
              <div class="col-md-7"></div>
              <div class="col-md-5 d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3">
                <b-button variant="info" @click="$router.push({name:'ap-eft-list'})" >Cancel</b-button>
                <b-button variant="primary" @click="complete_eft" >Make Complete</b-button>
              </div>
              <!-- end col -->
            </div>

            <div class="table-responsive">
              <div class="table align-middle table-nowrap">
                <b-table :items="eft.cash_transactions" :fields="fields" responsive="sm" :per-page="eft.cash_transactions.length" 
                  class="table-check">

                  <template #cell(ID)="data">
                    <input type="checkbox" @change="onSelectedTransaction($event, data.item)" checked/>
                  </template>

                  
                  <template #cell(Payee)="data">
                    {{ data.item.payee }}
                  </template>


                  <template #cell(EFTNumber)="data">
                    {{ data.item.bearer_number }}
                  </template>

                  <template #cell(Amount)="data">
                    ${{ data.item.amount.toLocaleString() }}
                  </template>

                  <template #cell(Date)="data">
                    {{ data.item.transaction_date }}
                  </template>

                  <template #cell(Remark)="data">
                    {{ data.item.remarks }}
                  </template>
                </b-table>
              </div>
            </div>
            <!-- end table responsive -->

          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
